import React from "react";
import Rows from "../components/Rows"
import { Spinner } from "reactstrap";
import './HomeBody.css'
import { asyncFetchNextPage, fetchNextRowBlock, getFirstRowBlock, getRecommendationsRow } from "../apis/home";
import { getUserId, logEventBeacon } from '../api'
import { getI18nextLanguage, homePageState, getSessionId } from "../utils/localStorage";
import Observer from '@researchgate/react-intersection-observer'
import {Link, Trans, withTranslation} from 'gatsby-plugin-react-i18next';
import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import AdComponent, { FullWidthAd } from "./AdComponent";

// getDisneyPlus = async () => {
//   const response = await fetch('https://cinetimes.org/api/fr/movie?watch_on=1&order=avg_rating&limit=20&offset=0', {
//     method: 'GET',
//   })
//   if (response.ok){
//     const result = await response.json()
//     const disneyplus = result.results
//     this.setState({ disneyplus })
//   }
// }

// getFromStreamingService = async (serviceName, serviceId) => {
//   const response = await fetch(`https://cinetimes.org/api/fr/movie?watch_on=${serviceId}&order=revenue&limit=20&offset=0`, {
//     method: 'GET',
//   })
//   if (response.ok){
//     const result = await response.json()
//     if (result.count > 0) {
//       const items = result.results
//       items.forEach(i => { i.slug = '/t/' + i.slug })
//       this.setState({ [serviceName]: items })
//     }
//   }
// }

const rowBlockFromRows = rows => {
  const lastRowBlock = rows.slice(rows.length - 4)
  const rowBlock = lastRowBlock.map(row => row.querystring).toString() //.replaceAll(",", "//")
  return rowBlock
}

const parseWatchOn = watchOnRaw => {
  const watchOn = []
  if (watchOnRaw['disneyplus']) watchOn.push(1)
  if (watchOnRaw['cinetimes']) watchOn.push(2)
  if (watchOnRaw['netflix']) watchOn.push(7)
  if (watchOnRaw['primevideo']) watchOn.push(8)

  if (!watchOn.length)watchOn.push(0)
  return watchOn
}

class HomeBody extends React.Component {
  state = {
    movies: [],
    history: [],
    disneyplus: null,
    netflix: null,
    amazonprimevideo: null,
    rows: null,
    error: false,
    errorMessage: '',
    loading: false,
    loadingRecs: true,
    language: null,
    impressions: [],
    pathname: "/"
  }

  componentWillMount() {
    if (typeof window !== "undefined") {
      require("intersection-observer")
    }
  }

  async componentDidMount(){
    const language = await getI18nextLanguage()
    
    // Do nothing with it, just fetch it to initialize the session timer
    getSessionId()
    
    this.setState({language: language})

    const savedState = await homePageState(this.props.slug, null, language)
    if (savedState) {
      await this.setState({...savedState})
      if (this.state.loadingRecs === true){
        if (this.props.slug === "/browse/free"){
          this.getRecRows()
        }
      }
      localStorage.removeItem('homepageSavedSlug')
      localStorage.removeItem('homepageState')
      localStorage.removeItem('homepageSavedDate')
      return
    }

    let history = await JSON.parse(localStorage.getItem('history'))
    this.setState({history})
    // this.getFromStreamingService('disneyplus', 1)
    // this.getFromStreamingService('netflix', 2)
    // this.getFromStreamingService('amazonprimevideo', 3)
    this.getRows()

    window.addEventListener("beforeunload", this.beforeunload);
    this.setState({pathname: window.location.pathname, randomBool: Math.random() < 0.50})
  }

  async componentWillUnmount(){
    this.sendImpressions()
    window.removeEventListener("beforeunload", this.beforeunload);
    // store entire state in cache
    homePageState(this.props.slug, this.state, this.state.language)
  }

  async componentDidUpdate(prevProps, prevState){
    if (prevProps.watchOn !== this.props.watchOn){
      this.getRows()
      this.setState({ rows: null })
    }
  }

  beforeunload = e => {
    logEventBeacon(
      "click", 
      this.state.pathname, 
      "MovieTranslation",
      this.state.impressions.toString(),
      null,
    )
  }

  // send impressions on click on some movie
  sendImpressions = itemId => {
    const {impressions, pathname} = this.state
    if (impressions.length > 2 ) {
      logEventBeacon(
        "click", 
        pathname, 
        "MovieTranslation",
        impressions.toString(),
        itemId,
      )
      trackCustomEvent({
        category: "MovieClick",
        action: itemId,
        label: impressions.toString(),
      })
      this.setState({ impressions: [] })
    }
  }

  addImpressionId = async id => {
    this.setState({
      impressions: Array.from(new Set([id, ...this.state.impressions])),
    })
  }


  getRecRows = async () => {
    let recs = 'videos'
    if (this.props.slug === '/'){
      // recs = 'movies'
      recs = 'videos'
    }

    const watchOnRaw = this.props.watchOn
    const watchOn = parseWatchOn(watchOnRaw)
    const slug = this.props.slug

    try {
      const rows = await getRecommendationsRow(watchOn, slug, recs)
      // if (rows[0].movies.length < 1) {
      //   throw new Error("Pas de recommandations pour le moment")  
      // }
      this.setState({ 
        rows: [ ...rows, ...this.state.rows],
        errorRecs: false, 
        loadingRecs: false, 
        errorMessage: '',
      })
    } catch (err){
      let message = "Pas de recommandations pour le moment"
      this.setState({
        errorRecs: true,
        loadingRecs: false,
        errorMessage: message,
      })
    }
  }

  getRows = async () => {
    const watchOnRaw = this.props.watchOn
    const watchOn = parseWatchOn(watchOnRaw)
    const slug = this.props.slug

    try {
      const {rowBlock, rows, total_rows} = await getFirstRowBlock(watchOn, slug)
      this.setState({ 
        rows:rows, 
        error: false, 
        errorMessage: '',
        rowBlock: rowBlock,
        total_rows: total_rows,
      })
      if (this.props.slug === "/browse/free"){
        this.getRecRows()
      }
      
    } catch (err){
      let message = "Problème de connexion, rechargez la page"
      this.setState({
        error: true,
        errorMessage: message,
      })
    }
  }

  async getNextRowBlock(){
    if (this.state.loading) return
    this.setState({loading: true})
    const {rows} = this.state
    const rowBlock = rows[rows.length-1].rowblock
    let page = Math.ceil(rows.length / 4 + 1)
    page = !this.state.loadingRecs ? page - 1 : page
    const slug = this.props.slug
    try {
      const nextRowBlock = await fetchNextRowBlock(rowBlock, page, slug)
      this.setState({
        rows: [...this.state.rows, ...nextRowBlock],
        error: false,
        errorMessage: '',
        loading: false,
      })
      
    } catch (err) {
      console.log(err.message)
      this.setState({loading: false})
    }

  }

  fetchNextPage = async (querystring, offset) => {
    const watchOnRaw = this.props.watchOn
    const watchOn = parseWatchOn(watchOnRaw) 
    try {
        const result = await asyncFetchNextPage(querystring, watchOn, offset)
        const movies = result.results.movies
        movies.forEach(m => {m.slug = '/t/' + m.slug })
        const rows = this.state.rows.map(row => {
          if (row.querystring === querystring) {
            row.movies = [...row.movies, ...movies]
            return row
          }
          else return row
        })
        this.setState({rows})

    } catch (err){
      let message = "Problème de connexion, rechargez la page"
      this.setState({
        error: true,
        errorMessage: message,
      })
    }
  }

  render() {
    let {history, disneyplus, netflix, amazonprimevideo, rows, error,errorMessage} = this.state

    history = history
    ? history.map(h => ({slug: `/t/${h.slug}`,poster_thumb: {url: h.poster} , title: h.title, id: h.slug}))
    : null

    let renderRows = null

    if (rows) {
      renderRows = rows.map(row => {
        if (!row.movies || !row.movies.length) return ''
        return <Rows 
          movies={row.movies} 
          title={row.title} 
          key={row.querystring}
          querystring={row.querystring}
          rowblock={row.rowblock}
          fetchNextPage={this.fetchNextPage}
          disabledMoreButton 
          sendImpressions = {this.sendImpressions}
          addImpressionId = {this.addImpressionId}
          />
      })
    }

    // const rows = 

    // Transform into streaming url
    // streaming.forEach(i => {
    //   if (!i.node.slug.endsWith('/watch')) i.node.slug += '/watch'
    // })

    const userId = getUserId()
    const {t} = this.props

    if (!rows) {
      return (
        <div className="m-auto text-center pt-4 pb-5 homebody">
          <div className="mb-2 px-3">
            <div className="m-auto col-12 text-center">
              <AdComponent client="ca-pub-3093835180445554" slot="5069158618" customClasses="my-2" format="horizontal"/> 
              {/* <FullWidthAd client="ca-pub-3093835180445554" slot="5069158618"/> */}
            </div>
          </div>
          { history && // this.state.rows.length >= this.state.total_rows &&
            <Rows movies={history} title={t('Vu récemment')} disabledMoreButton 
              sendImpressions = {() => {}}
              addImpressionId = {() => {}}
            />
          }
          {error 
            ? <span>{errorMessage}</span> 
            : <Spinner className="m-auto text-center" size="sm"/>
          }
        </div>
      )
    }

    return (
        <div className="homebody loaded">
          <div className="mb-2 px-3">
              <div className="m-auto col-12 text-center">
                <AdComponent client="ca-pub-3093835180445554" slot="5069158618" customClasses="my-2" format="horizontal"/> 
              </div>
            </div>

          { history && // this.state.rows.length >= this.state.total_rows &&
            <Rows movies={history} title={t('Vu récemment')} disabledMoreButton 
              sendImpressions = {() => {}}
              addImpressionId = {() => {}}
            />
          }

          {this.state.loadingRecs && (userId) && this.props.slug === "/browse/free" &&
            <Rows movies={[]} title={t('Recommandé pour vous')} disabledMoreButton 
              sendImpressions = {this.sendImpressions}
              addImpressionId = {this.addImpressionId}
            />
          }

          {renderRows}

  
          { this.state.rows.length < this.state.total_rows &&
            <div className="text-center my-5">
              { this.props.infinite &&
                <Observer onChange={() => this.getNextRowBlock()}rootMargin="0% 0% 25%">
                  <div className="text-center">{this.state.loading && <Spinner className="mx-5 text-center" size="sm"/>}</div>
                </Observer>
              }
              { this.props.infinite === false &&
                <div>
                  {
                    this.state.loading 
                    ? <button className="btn btn-warning font-weight-bold"> <Spinner className="mx-5 text-center" size="sm"/> </button>
                    : <button className="btn btn-warning font-weight-bold" onClick={() => this.getNextRowBlock()}>Charger plus</button>
                  }
                </div>
              }
            </div>  
          }
        </div>
    )
  }

}

export default withTranslation()(HomeBody)