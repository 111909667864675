import {url, getToken, lang} from '../api'
import { getGuestId, getI18nextLanguage } from "../utils/localStorage"

const rowBlockFromRows = rows => {
    const lastRowBlock = rows.slice(rows.length - 4)
    const rowBlock = lastRowBlock.map(row => row.querystring).toString() //.replaceAll(",", "//")
    return rowBlock
}


export const getRecommendationsRow = async (watchOn, slug="/", recs="movies") => {
    let guestId = null
    const token = await getToken()
    const headers = {'content-type': 'application/json'}
    
    
    if (token !== null) {
      headers['Authorization'] = `Token ${token}`
    } else {
      guestId = await getGuestId()
    }
    
    guestId = guestId === null ? '' : `&guest_id=${guestId}`;

    let lang = await getI18nextLanguage()
    const langParam = !lang ? '' :  `&language=${lang}`

    const url_recs = recs === 'movies' 
    ? `${url}/rows/recommendations/movies?slug=${slug}`
    : `${url}/rows/recommendations?watch_on=${watchOn.toString()}&slug=${slug}${guestId}${langParam}`

    const response = await fetch(url_recs, {
        headers: headers,
        method: 'GET',
    })

    if (response.ok){
      const result = await response.json()
      const rows = result.results.filter(Boolean)
      const rowblock = rowBlockFromRows(rows)
      rows.forEach(row => {
        row.rowblock = rowblock
        row.movies.forEach(m => {
          m.slug = '/t/' + m.slug
        })
      })
      
      return rows
    }
  
    const errMessage = await response.json()
    throw new Error(JSON.stringify(errMessage))  
}


export const getFirstRowBlock = async (watchOn, slug="/") => {
    let guestId = null
    let lang = await getI18nextLanguage()
    const token = await getToken()
    const headers = {'content-type': 'application/json'}

    if (token !== null) {
      headers['Authorization'] = `Token ${token}`
    } else {
      guestId = await getGuestId()
    }

    guestId = guestId === null ? '' : `&guest_id=${guestId}`;
    const langParam = !lang ? '' :  `&language=${lang}`

    const response = await fetch(`${url}/rows/?watch_on=${watchOn.toString()}&slug=${slug}${guestId}${langParam}`, {
        headers: headers,
        method: 'GET',
    })

    if (response.ok){
      const result = await response.json()
      const total_rows = result.total_results
      const rows = result.results.filter(Boolean)
      const rowblock = rowBlockFromRows(rows)
      rows.forEach(row => {
        row.rowblock = rowblock
        row.movies.forEach(m => {
          m.slug = '/t/' + m.slug
        })
      })

      return {rowBlock: rowblock, rows:rows, total_rows:total_rows}
    }
  
    const errMessage = await response.json()
    throw new Error(JSON.stringify(errMessage))  
}


export const fetchNextRowBlock = async (previousRowBlock, page, slug="/") => {

    const token = await getToken()
    const headers = {'content-type': 'application/json'}
    if (token !== null) headers['Authorization'] = `Token ${token}`

    let lang = await getI18nextLanguage()
    const langParam = !lang ? '' :  `&language=${lang}`

    const response = await fetch(`${url}/rows/rowblock/after/${previousRowBlock}?page=${page}&slug=${slug}${langParam}`, {
        headers: headers,
        method: 'GET',
    })

    if (response.ok) {
      const result = await response.json()
      const rows = result.results.filter(Boolean)
      const rowblock = rowBlockFromRows(rows)
      rows.forEach(row => {
        row.rowblock = rowblock
        row.movies.forEach(m => {
          m.slug = '/t/' + m.slug 
        })
      })
      return rows
    }
  
    const errMessage = await response.json()
    throw new Error(JSON.stringify(errMessage))  

}

export const asyncFetchNextPage = async (querystring, watchOn, offset) => {

  let lang = await getI18nextLanguage()
  const langParam = !lang ? '' :  `&language=${lang}`


  const response = await fetch(`${url}/rows/${querystring}?watch_on=${watchOn.toString()}&offset=${offset}${langParam}`, { 
    method: 'GET',
  })
  
  if (response.ok) {
    const result = await response.json()
    return result
  }

  const errMessage = await response.json()
  throw new Error(JSON.stringify(errMessage))  
}